import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageNotFoundComponent } from './features/page-not-found/page-not-found.component';
import { ToolbarComponent } from './features/toolbar/toolbar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { SapphireComponent } from './shared/sapphire/sapphire.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { LoginComponent } from './auth/login/login.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import { BurgerMenuComponent } from './features/menu/burger-menu/burger-menu.component';
import { LoggingInterceptor } from './http-interceptor.interceptor'
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { NgxLoadingModule } from "ngx-loading-reloaded";
import { AuthService } from './shared/services/auth-service';
import { TokenIntegrationService } from './shared/services/token-integration.service';
import { AuthGuard, AdminOnlyGuard } from './shared/guards/authGuard';
import { ClosedReviewMessageComponent } from './shared/components/closed-review-message/closed-review-message.component';
import {MatDividerModule} from '@angular/material/divider';
import { StoreModule } from '@ngrx/store';
import { seoListReducer } from './shared/store/states/seoList/seoList.reducer';
import { EffectsModule } from '@ngrx/effects';
import { seoListEffects } from './shared/store/states/seoList/seoList.effect';

@NgModule({ declarations: [
        AppComponent,
        PageNotFoundComponent,
        ToolbarComponent,
        SapphireComponent,
        BurgerMenuComponent,
        ClosedReviewMessageComponent
    ],
    exports: [
        ToolbarComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        MatDividerModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatButtonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatExpansionModule,
        NgxLoadingModule.forRoot({}),
        MatRadioModule,
        MatCheckboxModule,
        MatSidenavModule,
        MatInputModule,
        MatFormFieldModule,
        StoreModule.forRoot({ seoSlice: seoListReducer }),
        EffectsModule.forRoot([seoListEffects])], providers: [
        {
            provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true
        },
        AuthService,
        AuthGuard,
        AdminOnlyGuard,
        TokenIntegrationService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
