import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-closed-review-message',
  templateUrl: './closed-review-message.component.html',
  styleUrl: './closed-review-message.component.scss'
})
export class ClosedReviewMessageComponent {
  @Input() title:string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string
    }
  ){
    
  }

  ngOnInit(){
    this.title = this.data.title
  }

}
