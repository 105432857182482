import { Component, Output, EventEmitter, signal } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth-service';
import { environment } from 'src/environments/environment';
interface userDisplay{
  name: string
  lastName: string
}
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})


export class ToolbarComponent {
  showFiller = false;
  readonly panelOpenState = signal(false);
  public drawer?: MatDrawer
  userDetail: userDisplay = {
    name: '',
    lastName: ''
  }
  constructor(
    private _router: Router,
    private _authService: AuthService
  ){

  }
  @Output() onMenuClicked = new EventEmitter<any>();

  ngOnInit(){
    this.getUserDetails()
  }

  menuClick(){
    this.onMenuClicked.emit("menuClicked")
  }

  goHome(){
    this._router.navigate(['/'])
  }

  logout(){
    document.location.href = environment.uamUrl + '/auth/logout';
  }

  getUserDetails(){
    let det = this._authService.getUserDetails()
    this.userDetail.lastName = det.payload['family_name']?.toString() as string
    this.userDetail.name = det.payload['name']?.toString() as string
  }
}
