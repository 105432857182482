import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
// import { Amplify, Auth, AuthConfig } from 'aws-amplify';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LibraryOptions, ResourcesConfig } from '@aws-amplify/core';
import { signIn } from 'aws-amplify/auth';
import { decodeJWT } from 'aws-amplify/auth';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';

// import amplifyconfig from '../../../environments/test.env.json';
// import amplifyconfig from './amplifyconfiguration.json';

// Amplify.configure(amplifyconfig);

@Injectable()
export class AuthService {
  public loggedIn: BehaviorSubject<boolean>;
  idToken: string
  refreshToken: string
  deviceKey: string
  constructor(
    private _router: Router,
    @Inject(DOCUMENT) private document: Document,
    private _httpClient: HttpClient,
    private cookieService: CookieService
  ) {
    this.setAuthTokens()
    this.verifyTokenExpiration()
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }
  private baseUrl = environment.endpoint


  public login(username: string, password: string) {
    return signIn({ username, password })
  }

  public setIdToken(idToken:string){
    this.idToken = idToken
    this.cookieService.set('uam-id-token', idToken as string, { domain: environment.cookieInfo.domain})
  }

  public verifyTokenExpiration(){
    try{
      let decodedIdToken = decodeJWT(this.idToken)
    }
    catch(err){
      window.location.href = environment.uamUrl;
      // this.document.location.href = environment.uamUrl
    }


  }

  public setAuthTokens() {
    this.idToken = this.cookieService.get('uam-id-token')
    this.refreshToken = this.cookieService.get('uam-refresh-token')
    this.deviceKey = this.cookieService.get('uam-device-key')
  }

  public refreshTokenFunction(refreshToken: string): Observable<any> {
    try{
      return this._httpClient.post<any>(`${environment.refreshEndpoint}`, { refreshToken: this.refreshToken, deviceKey: this.deviceKey }).pipe(res => {
        return res
      })
    }
    catch(err)
    {
      // console.log("catch this", err)
      return new Observable<any>(subscriber => subscriber.next());
    }
  }

  public getSettings() {
    try{
      return this._httpClient.post<any>(`${this.baseUrl}/auth/getSettings`, {}).pipe(res => {
          return res
      })
    }catch(err){
        // console.log("error on docs to send rank", docsToSend)
        return new Observable<any>(subscriber => subscriber.next());
    }
  }

  public getIdToken() {
    return this.idToken
  }

  public getUserDetails(){
    let decodedIdToken = decodeJWT(this.idToken)
    return decodedIdToken
  }

  public getUserRole(){
    let decodedIdToken = decodeJWT(this.idToken)
    return decodedIdToken.payload['custom:safire_role']
  }

  public getRefreshToken() {
    return this.refreshToken
  }


}
