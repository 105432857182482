<mat-toolbar class="menu">
    <div class="grid grid-cols-3 gap-4 width-100">
        <div class="width-100 content-center" id="sapphire">
            <!-- <button (click)="drawer.toggle()">
                <mat-icon class="document-icon">menu</mat-icon>
            </button> -->
            <div class="grid grid-cols-2 width-100">
                <div class="flex">
                    <!-- <img class="sapphire-image" src="../../../assets/svgs/sapphire.svg"/> -->
                    <div class="toolbar-item-holder">
                        <button class="toolbar-item" (click)="menuClick()">
                            <span class="material-symbols-outlined menu-icon">
                                menu
                            </span>
                        </button>
                    </div>
                    <div class="toolbar-item-holder">
                        <button class="home-button" (click)="goHome()">
                            <span class="tool-bar-color-text toolbar-item">
                                <!-- <img src="../../../assets/images/menu.jpg" class="img-go"> -->
                                Safire
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-span-2 justify-end content-end flex user-info">
            @if(userDetail)
            {
                <mat-drawer-container class="user-menu-right" autosize>
                    
                    
                    <div class="user-options-holder" (click)="drawer?.toggle()">
                        <div class="grid grid-cols-3 user-options-child">
                            <div class="col-span-2 detail-holder">
                                Hello, {{userDetail.name}} {{userDetail.lastName}}
                            </div>
                            <div class="icon-holders col-span-1">
                                <i class="material-icons">person</i>
                                @if(drawer.opened){
                                    <i class="material-icons">keyboard_arrow_right</i>
                                }
                                @if(!drawer.opened){
                                    <i class="material-icons">keyboard_arrow_left</i>
                                }
                            </div>
                        </div>
                    </div>
                    <mat-drawer #drawer class="user-sidenav" mode="side" position="end">
                        <div class="table-parent">
                            <div class="table-child">
                                <button mat-flat-button color="primary" class="logout-button" (click)="logout()">Logout</button>
                            </div>
                        </div>
                    </mat-drawer>
                </mat-drawer-container>
                
               
               
            }
        </div>
        <!-- <div class="width-100">taskbar option 2</div>
        <div class="width-100">taskbar option 3</div> -->
      </div>
</mat-toolbar>
