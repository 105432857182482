import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { decodeJWT } from 'aws-amplify/auth';
import { environment } from "src/environments/environment";

@Injectable()
export class TokenIntegrationService {
    IdToken = ''
    refreshToken = ''

    constructor(
        private _router: Router,
        private _cookieService: CookieService
    ){

    }

    verifyTokenExpiration(token: string){
        let decodedToken = decodeJWT(token)
    }

    getTokensFromCookies(){
        
    }

    verifyTokens(token:string){

    }

    

    redirectToUam(){
        this._router.navigateByUrl(environment.uamUrl)
    }

}