export const environment = {
    production: false,
    useDevToken: false,
    devToken: '',
    name: 'qa',
    version: '1.0.0',
    region: 'us-gov-west-1',
    url: 'https://safire-qa.cristlgov.com/home',
    endpoint: 'https://qa-be.safire-ai.com',
    sandboxEnabled: false,
    refreshEndpoint: 'https://qa-be.cristlgov.com/auth/refreshToken',
    uamUrl: 'https://qa.cristlgov.com', 
    cookieInfo: {
      domain: '.cristlgov.com'
    },
    amplify: {
      Auth: {
        region: 'us-gov-west-1',
        userPoolId: 'us-gov-west-1_MPVKbg8l7',
        userPoolWebClientId: '5gdg5k8pbphui6rskjh1edfvhh',
      },
    },
  };
  